import React, { createContext, useMemo, useReducer } from "react";
// import { checkIfLoggedIn, getActiveUser, setActiveUser } from "../api/accountService";

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME':
      if (typeof window !== 'undefined') {
        localStorage.setItem('ip_dark_theme', !state.isDarkTheme);
      }

      return {
        ...state,
        isDarkTheme: !state.isDarkTheme
      }
      break;
    case 'TOGGLE_NAV':
      if (typeof window !== 'undefined') {
        localStorage.setItem('ip_nav_oppened', !state.isNavOpened);
      }

      return {
        ...state,
        isNavOpened: !state.isNavOpened
      }
      break;
    case 'SET_USER':
      const user = action.currentUser;
      // if (user) {
      //   setActiveUser({
      //     id: user.id,
      //     email: user.email,
      //     username: user.username,
      //     firstname: user.firstname,
      //     lastname: user.lastname,
      //     isDevTeam: user.isDevTeam
      //   });
      // }

      return {
        ...state,
        currentUser: user
      }
      break;
    case 'SHOW_COMING_SOON':
      return {
        ...state,
        showComingSoonPopup: true
      }
      break;
    case 'OPEN_EMAIL_DIALOG':
      location.href="mailto:"+action.to+"?subject="+action.subject;
      return {
        ...state
      }
      break;
    case 'SHOW_LOGIN':
      return {
        ...state,
        showLoginPopup: true
      }
      break;
    case 'SHOW_REGISTRATION':
      return {
        ...state,
        showRegistrationPopup: true
      }
      break;
    case 'HIDE_POPUP':
      return {
        ...state,
        showLoginPopup: false,
        showRegistrationPopup: false,
        showComingSoonPopup: false
      }
      break;
    case 'SET_SEARCH_CODE':
      return {
        ...state,
        searchCode: action.searchCode,
        searchItem: !action.searchCode ? state.searchItem : null,
        searchTags: !action.searchCode ? state.searchTags : null
      }
      break;
    case 'SET_SEARCH_ITEM':
      return {
        ...state,
        searchCode: null,
        searchItem: action.searchItem,
        searchTags: null,
      }
      break;
    case 'SET_SEARCH_TAGS':
      return {
        ...state,
        searchCode: null,
        searchItem: null,
        searchTags: action.searchTags
      }
      break;
    case 'SET_FAVORITES_ITEMS':
      return {
        ...state,
        favoritesItems: action.favoritesItems
      }
      break;
    case 'SET_ONMOBILE':
      return {
        ...state,
        onMobile: action.onMobile
      }
      break;
    case 'SET_FULLSCREEN':
      return {
        ...state,
        onFullscreen: action.onFullscreen
      }
      break;
    case 'SET_ACTIVE_VIDEO':
      return {
        ...state,
        activeVideo: action.activeVideo
      }
      break;
    default:
      break;
  }
}

const GlobalContextProvider = ({children}) => {
  const initialState = useMemo(() => {
    return {
      currentUser: null,
      isDarkTheme: (typeof window !== 'undefined') && localStorage?.getItem('ip_dark_theme') === 'false' ? false : true,
      isNavOpened: (typeof window !== 'undefined') && localStorage?.getItem('ip_nav_oppened') === 'false' ? false : true,
      showLoginPopup: false,
      showComingSoonPopup: false,
      showRegistrationPopup: false
    }
  }, []);
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}

export default GlobalContextProvider;