import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Trans, withI18n } from '@lingui/react';
import { Link, navigate } from 'gatsby';
import { prefix } from '../../i18n-config';

import classNames from 'classnames';
import * as styles from './styles.module.scss';
import { GlobalStateContext, GlobalDispatchContext } from '../../context/GlobalContextProvider';
import { Button, Dropdown } from 'react-bootstrap';
import { useResizeDetector } from 'react-resize-detector';
import InterstellarLogo from '../img/InterstellarLogo';
import MoonIcon from '../img/MoonIcon';
import GlobeIcon from '../img/GlobeIcon';
import { langFromPath, languages } from '../../i18n-config';
import { map } from 'lodash';
import SunIcon from '../img/SunIcon';
import SlidersIcon from '../img/SlidersIcon';
import CanadaFlagIcon from '../img/CanadaFlagIcon';
import QuebecFlagIcon from '../img/QuebecFlagIcon';
import moment from 'moment';
import CloseIcon from '../img/CloseIcon';
import MenuIcon from '../img/MenuIcon';

function HeaderNav(props) {
  const { pageContext, data, location } = props;
  const lang = pageContext?.lang || props.lang;
  const langPrefix = prefix(lang);
  const globalState = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [onMobile, setOnMobile] = useState(false);
  const [onFullscreen, setOnFullscreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(globalState?.isDarkTheme);
  const [showSubNavs, setShowSubNavs] = useState(globalState?.isNavOpened);
  const [showSidebar, setShowSidebar] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);

  const scrollTopPos = useRef();
  
  const headerRef = useRef();
  const { width } = useResizeDetector({
    handleHeight: false,
    targetRef: headerRef
  });

  const languageOptions = {
    en: 'English',
    fr: 'French'
  }

  useEffect(() => {
    if (globalState.isDarkTheme) {
      document.body.classList.add('dark-theme');
      document.body.classList.remove('light-theme');
    } else {
      document.body.classList.add('light-theme');
      document.body.classList.remove('dark-theme');
    }
    setIsDarkTheme(globalState.isDarkTheme);
  }, [globalState?.isDarkTheme]);

  useEffect(() => {
    console.log(globalState.isNavOpened);
    setShowSubNavs(globalState.isNavOpened);
  }, [globalState?.isNavOpened]);

  useEffect(() => {
    globalDispatch({
      type: 'SET_ONMOBILE',
      onMobile: window.innerWidth < 768
    });

    setShowSidebar(window.innerWidth < 1240);
  }, [width, (typeof window !== 'undefined') && window.innerWidth]);

  useEffect(() => {
    setOnMobile(globalState.onMobile);
  }, [globalState?.onMobile]);

  useEffect(() => {
    console.log(navigator, location)
  }, []);

  useEffect(() => {
    setCurrentUser(globalState.currentUser);
  }, [globalState?.currentUser]);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('scroll', handleScroll);
  //     handleScroll();
  //   }

  //   () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   }
  // }, []);

  // const handleScroll = () => {
  //   const scrollTop = window.scrollY;
  //   setShowSubNavs(scrollTop < scrollTopPos.current || scrollTop === 0);
  //   scrollTopPos.current = scrollTop;
  // }

  const onSignIn = () => {
    globalDispatch({
      type: 'SHOW_LOGIN'
    });
  }

  const onSignUp = () => {
    globalDispatch({
      type: 'SHOW_REGISTRATION'
    });
  }

  const onCloseLoginPopup = () => {
    globalDispatch({
      type: 'HIDE_POPUP'
    });
  }

  const onNavClick = (e) => {
    e.preventDefault();

    globalDispatch({
      type: 'SHOW_COMING_SOON'
    });
  }

  const onToggleNav = () => {
    setShowSubNavs((prevState) => !prevState);

    globalDispatch({
      type: 'TOGGLE_NAV'
    });
  }

  const onSetTheme = (e) => {
    setIsDarkTheme((prevState) => !prevState);

    globalDispatch({
      type: 'TOGGLE_THEME'
    });
  }

  const renderLangButton = () => {
    const lang = langFromPath(location.pathname);

    const getUrlPath = () => {
      let path = '';

      if (lang === 'en') {
        path = `/${languages[1]}${location.pathname}`;
      } else {
        path = location.pathname.substr(3);
      }

      return path;
    }

    return (
      <Button
        as={Link}
        size='sm'
        className={styles.langToggleBtn}
        to={getUrlPath()}
      >
        {lang !== 'en' ? (
          <CanadaFlagIcon />
        ) : (
          <QuebecFlagIcon />
        )} 
        <span>
          <span style={{textTransform: 'uppercase' }}>{lang !== 'en' ? languages[0] : languages[1]}</span>(Canada)
        </span>
      </Button>
    );
  }

  const renderLanguageOptions = () => {
    const lang = langFromPath(location.pathname);
    const getUrlPath = (language) => {
      let path = '';

      if (language === lang) {
        path = location.pathname;
      } else if (language === 'en') {
        path = location.pathname.substr(3);
      } else {
        path = `/${language}${location.pathname}`;
      }

      return path;
    }

    return map(languages, (language, i) => {
      return <Dropdown.Item
        as={Link}
        key={i}
        to={getUrlPath(language)}
      >
        {languageOptions[language]}
      </Dropdown.Item>
    });
  }

  const renderLanguageOption = () => {
    const lang = langFromPath(location.pathname);
    return (
      <Dropdown drop='bottom' className='dropdown'>
        <Dropdown.Toggle variant={'link'} >
          <GlobeIcon />
          <div>
            <span style={{textTransform: 'uppercase' }}>{lang}</span>(Canada)
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {renderLanguageOptions()}
        </Dropdown.Menu>
      </Dropdown>
    );
  }


  const onCloseFullscreen = (e) => {
    e.stopPropagation();
    globalDispatch({
      type: 'SET_FULLSCREEN',
      onFullscreen: false
    });
  }

  const renderMainNavs = () => {
    return (
      <div className={styles.mainNavsWrapper}>
        <div className='container'>
          <Link className={styles.logo} to='/'>
            <InterstellarLogo />
          </Link>

          {showSidebar ? (
            renderSidebar()
          ) : (
            renderMainNavsOptions()
          )}
        </div>
      </div>
    );
  }

  const renderMainNavsOptions = () => {
    return (
      <>
        <div className={styles.leftWrapper}>
          <Button
            as={Link}
            to={`${langPrefix}/`}
            onClick={onNavClick}
          >
            <Trans>Our Studio</Trans>
          </Button>
          <Button
            as={Link}
            to={`${langPrefix}/`}
            onClick={onNavClick}
          >
            <Trans>Our Work</Trans>
          </Button>
          <Button
            as={Link}
            to={`${langPrefix}/service-centre/pricing`}
            onClick={onNavClick}
          >
            <Trans>Pricing</Trans>
          </Button>
          <Button
            as={Link}
            to={`${langPrefix}/`}
            onClick={onNavClick}
          >
            <Trans>Merch</Trans>
          </Button>
          <Button disabled>
            <Trans>Manage</Trans>
          </Button>
        </div>

        <div className={styles.rightWrapper}>
          <Button
            variant='dark'
            as={Link}
            onClick={onNavClick}
            to={`${langPrefix}/book`}
          >
            <Trans>Book Now</Trans>
          </Button>
          <Button
            as={Link}
            to={`${langPrefix}/service-centre`}
            onClick={onNavClick}
          >
            Service Centre
          </Button>
          <Button
            as={Link}
            to={`${langPrefix}/`}
            onClick={onNavClick}
          >
            <Trans>Customer Area</Trans>
          </Button>
          <Button
            as={Link}
            to={`${langPrefix}/`}
            onClick={onNavClick}
          >
            <Trans>Contact</Trans>
          </Button>
          <Button
            as={Link}
            to={`${langPrefix}/`}
            onClick={onNavClick}
          >
            <Trans>Login</Trans>
          </Button>
          <Button
            // variant='link'
            className={styles.navToggleBtn}
            onClick={onToggleNav}
          >
            <SlidersIcon />
          </Button>
        </div>
      </>
    );
  }

  const renderSubNavs = () => {
    return (
      <div className={classNames(styles.subNavsWrapper, {[styles.show]: showSubNavs})}>
        <span>
          &copy; {moment().format('Y')} Interstellar Productions.{(showSidebar && openSideBar) ? <br /> : ''} <Trans>All rights reserved.</Trans>
        </span>
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.themeToggleBtn}
            size='sm'
            onClick={onSetTheme}
          >
            {!isDarkTheme ? (
              <>
                <MoonIcon /> <Trans>Dark Mode</Trans>
              </>
            ) : (
              <>
                <SunIcon /> <Trans>Light Mode</Trans>
              </>
            )} 
          </Button>
          {renderLangButton()}
        </div>
      </div>
    );
  }

  const renderSidebar = () => {
    return (
      <>
        <Button
          active={openSideBar}
          className={styles.toggleBtn}
          onClick={() => setOpenSideBar(!openSideBar)}
        >
          {!openSideBar ? <MenuIcon /> : <CloseIcon />}
        </Button>
        {openSideBar && (
          <div className={styles.sidebarOverlay} onClick={() => setOpenSideBar(!openSideBar)} />
        )}
        <div className={classNames(styles.sidebar, {[styles.open]: openSideBar})}>
          {renderMainNavsOptions()}
          {renderSubNavs()}
        </div>
      </>
    );
  }

  return (
    <header
      ref={headerRef}
      className={classNames(styles.headerNav, {[styles.open]: showSubNavs})}
    >
      {renderMainNavs()}
      {!showSidebar && (
        renderSubNavs()
      )}
    </header>
  );
}

export default withI18n()(HeaderNav);

