// extracted by mini-css-extract-plugin
export var buttonsWrapper = "styles-module--buttonsWrapper--177e0";
export var headerNav = "styles-module--headerNav--32d58";
export var langToggleBtn = "styles-module--langToggleBtn--a3e2c";
export var leftWrapper = "styles-module--leftWrapper--6951f";
export var logo = "styles-module--logo--d9f65";
export var mainNavsWrapper = "styles-module--mainNavsWrapper--97632";
export var navToggleBtn = "styles-module--navToggleBtn--16fdf";
export var open = "styles-module--open--97321";
export var rightWrapper = "styles-module--rightWrapper--8d500";
export var show = "styles-module--show--b71bb";
export var showOverlay = "styles-module--showOverlay--40de3";
export var sidebar = "styles-module--sidebar--bb8ba";
export var sidebarOverlay = "styles-module--sidebarOverlay--43872";
export var subNavsWrapper = "styles-module--subNavsWrapper--00fbb";
export var themeToggleBtn = "styles-module--themeToggleBtn--ae6af";
export var toggleBtn = "styles-module--toggleBtn--bb17c";